// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-input-group {
  display: flex;
  padding: 5px 0;
}

.policy-input-group div:first-child {
  width: 20%;
}

.policy-input-group div:nth-child(2) {
  width: 70%;
  padding: 2px 0;
}

.policy-input-group input[type="text"] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.policy-input-group input[type="checkbox"] {
  margin-right: 4px;
}

.policy-textarea-group {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}
.policy-textarea-group div:first-child {
  margin-bottom: 8px;
}

.policy-textarea-group textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminPolicyEdit.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,2CAA2C;EAC3C,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".policy-input-group {\r\n  display: flex;\r\n  padding: 5px 0;\r\n}\r\n\r\n.policy-input-group div:first-child {\r\n  width: 20%;\r\n}\r\n\r\n.policy-input-group div:nth-child(2) {\r\n  width: 70%;\r\n  padding: 2px 0;\r\n}\r\n\r\n.policy-input-group input[type=\"text\"] {\r\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\r\n  width: 100%;\r\n}\r\n\r\n.policy-input-group input[type=\"checkbox\"] {\r\n  margin-right: 4px;\r\n}\r\n\r\n.policy-textarea-group {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 5px 0;\r\n}\r\n.policy-textarea-group div:first-child {\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.policy-textarea-group textarea {\r\n  border: 1px solid rgba(0, 0, 0, 0.1);\r\n  padding: 5px 10px;\r\n  border-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
