// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alimtalk-time_container {
  display: flex;
  gap: 20px;
  font-size: 20px;
}
.alimtalk-time_container label {
  display: flex;
  gap: 10px;
}

.labelClass {
  display: flex;
  gap: 40px;
  padding: 20px 0;
}

.date-time {
  border: 1px solid lightgray;
  padding: 2px 10px;
  margin-left: 15px;
}
.help-container {
  width: 100%;
  height: 300px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.help-title {
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 40px;
  font-size: 18px;
  font-weight: 600;
}
.help-title span {
  margin-right: 0 5px;
}
.help-content {
  padding: 40px;
}
.help_question {
  font-weight: 500;
  padding: 4px 0;
  font-size: 18px;
}
.help_answer {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProfileModified.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;AACA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".alimtalk-time_container {\r\n  display: flex;\r\n  gap: 20px;\r\n  font-size: 20px;\r\n}\r\n.alimtalk-time_container label {\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n\r\n.labelClass {\r\n  display: flex;\r\n  gap: 40px;\r\n  padding: 20px 0;\r\n}\r\n\r\n.date-time {\r\n  border: 1px solid lightgray;\r\n  padding: 2px 10px;\r\n  margin-left: 15px;\r\n}\r\n.help-container {\r\n  width: 100%;\r\n  height: 300px;\r\n  border: 1px solid lightgray;\r\n  border-radius: 20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: hidden;\r\n}\r\n.help-title {\r\n  width: 100%;\r\n  height: 60px;\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0 40px;\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n}\r\n.help-title span {\r\n  margin-right: 0 5px;\r\n}\r\n.help-content {\r\n  padding: 40px;\r\n}\r\n.help_question {\r\n  font-weight: 500;\r\n  padding: 4px 0;\r\n  font-size: 18px;\r\n}\r\n.help_answer {\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
