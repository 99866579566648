// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher-history {
  width: 100%;
  height: 100%;
}

.teacher-history__search {
  width: 100%;
  padding: 15px 0;
  display: flex;
}

/* 테이블 시작 */
.teacher-history table {
  width: 100%;
}
.teacher-history thead {
  background-color: #5a7087;
  color: white;
}
.teacher-history thead td {
  padding: 10px 0;
  text-align: center;
}
.teacher-history tbody tr {
  border-bottom: 1px solid lightgray;
}
.teacher-history tbody td {
  padding: 10px 0;
  text-align: center;
  vertical-align: center;
}


/* 테이블 끝 */
`, "",{"version":3,"sources":["webpack://./src/app/teacher/TeacherHistory.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;AACf;;AAEA,WAAW;AACX;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;;;AAGA,UAAU","sourcesContent":[".teacher-history {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.teacher-history__search {\r\n  width: 100%;\r\n  padding: 15px 0;\r\n  display: flex;\r\n}\r\n\r\n/* 테이블 시작 */\r\n.teacher-history table {\r\n  width: 100%;\r\n}\r\n.teacher-history thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n.teacher-history thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n.teacher-history tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.teacher-history tbody td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n\r\n\r\n/* 테이블 끝 */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
