// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-user-detail {
}

.input-group {
  position: relative;
  width: 100%;
}

.input-group .form-errors {
  position: absolute;
  right: 0;

  font-size: 14px;
  color: tomato;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminUserDetail.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;;EAER,eAAe;EACf,aAAa;AACf","sourcesContent":[".admin-user-detail {\r\n}\r\n\r\n.input-group {\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.input-group .form-errors {\r\n  position: absolute;\r\n  right: 0;\r\n\r\n  font-size: 14px;\r\n  color: tomato;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
