// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section h2 {
  font-weight: 600;
  padding: 15px 5px;
}
.security-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* font-size: 32px; */
}

.security-search {
  display: flex;
  width: 400px;
  height: 60px;
  align-items: center;
  gap: 8px;
}

.security-container section {
  padding: 20px 0;
}

.security-search__label {
  width: 100px;
}

.security-container table {
  width: 100%;
}

.security-container thead {
  background-color: #5a7087;
  color: white;
}
.security-container thead tr {
}
.security-container thead td {
  padding: 10px 0;
  text-align: center;
}

.security-container tbody tr {
  border-bottom: 1px solid lightgray;
}
.security-container tbody td {
  padding: 10px 0;
  text-align: center;
}

.table-accent {
  background-color: #d0f1d6;
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.file-upload-text{
  color: red;
    font-size: 13px;
    font-weight: 600;
    padding: 18px 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/security/SecurityStatus.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;AACA;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,UAAU;IACR,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":["section h2 {\r\n  font-weight: 600;\r\n  padding: 15px 5px;\r\n}\r\n.security-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  /* font-size: 32px; */\r\n}\r\n\r\n.security-search {\r\n  display: flex;\r\n  width: 400px;\r\n  height: 60px;\r\n  align-items: center;\r\n  gap: 8px;\r\n}\r\n\r\n.security-container section {\r\n  padding: 20px 0;\r\n}\r\n\r\n.security-search__label {\r\n  width: 100px;\r\n}\r\n\r\n.security-container table {\r\n  width: 100%;\r\n}\r\n\r\n.security-container thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n.security-container thead tr {\r\n}\r\n.security-container thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.security-container tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.security-container tbody td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.table-accent {\r\n  background-color: #d0f1d6;\r\n  font-size: 20px;\r\n  color: black;\r\n  font-weight: 600;\r\n}\r\n.file-upload-text{\r\n  color: red;\r\n    font-size: 13px;\r\n    font-weight: 600;\r\n    padding: 18px 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
