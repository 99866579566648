// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-confirm-detail {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-confirm-detail section {
  width: 90%;
  padding: 10px 0;
}

.reg-title {
  display: flex;
}

.input-group {
  width: 100%;

  display: flex;
  padding: 10px 10px;
}
.input-group div:first-child {
  width: 180px;
}
.input-group div:last-child {
  width: 75%;
}
.input-group input,
.input-group select {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

select:focus {
  outline: none;
}

.btn-container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.radio-container {
  width: 400px;
  display: flex;
  flex-direction: column;
}
.radio-container div {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.radio-container label,
.radio-container input {
  cursor: pointer;
}
.radio-container div input {
  width: 50px;
}

.input-group .calendar {
  width: 100%;
  background-color: red;
}

.input-selected {
  background-color: lightgray;

  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminConfirmDetail.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;;EAEX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;;EAEE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;;EAEE,eAAe;AACjB;AACA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;;EAE3B,gBAAgB;AAClB","sourcesContent":[".admin-confirm-detail {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.admin-confirm-detail section {\r\n  width: 90%;\r\n  padding: 10px 0;\r\n}\r\n\r\n.reg-title {\r\n  display: flex;\r\n}\r\n\r\n.input-group {\r\n  width: 100%;\r\n\r\n  display: flex;\r\n  padding: 10px 10px;\r\n}\r\n.input-group div:first-child {\r\n  width: 180px;\r\n}\r\n.input-group div:last-child {\r\n  width: 75%;\r\n}\r\n.input-group input,\r\n.input-group select {\r\n  width: 100%;\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n\r\nselect:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-container {\r\n  width: 100%;\r\n  padding: 20px 0;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n.radio-container {\r\n  width: 400px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n.radio-container div {\r\n  width: 100%;\r\n  height: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.radio-container label,\r\n.radio-container input {\r\n  cursor: pointer;\r\n}\r\n.radio-container div input {\r\n  width: 50px;\r\n}\r\n\r\n.input-group .calendar {\r\n  width: 100%;\r\n  background-color: red;\r\n}\r\n\r\n.input-selected {\r\n  background-color: lightgray;\r\n\r\n  font-weight: 500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
