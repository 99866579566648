// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-policy {
}
/* 테이블 시작 */
.admin-policy table {
  width: 100%;
  margin: 40px 0;
}

.admin-policy thead {
  background-color: #5a7087;
  color: white;
}

.admin-policy thead td {
  padding: 10px 0;
  text-align: center;
}

.admin-policy tbody tr {
  border-bottom: 1px solid lightgray;
}
.admin-policy tbody td {
  position: relative;
  padding: 10px 0;
  text-align: center;
  vertical-align: center;
}
.admin-policy input {
  width: 100%;
  border: 1px solid lightgray;
  padding: 5px 10px;
}
.admin-policy .title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 20px;
  font-weight: 600;
  font-size: 28px;
}

.admin-policy .edit-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  padding: 0 5px;
}

/* 테이블 끝 */

.save-group {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.admin-policy .remark-container {
  padding: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminPolicy.css"],"names":[],"mappings":"AAAA;AACA;AACA,WAAW;AACX;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,cAAc;AAChB;;AAEA,UAAU;;AAEV;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".admin-policy {\r\n}\r\n/* 테이블 시작 */\r\n.admin-policy table {\r\n  width: 100%;\r\n  margin: 40px 0;\r\n}\r\n\r\n.admin-policy thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n\r\n.admin-policy thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.admin-policy tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.admin-policy tbody td {\r\n  position: relative;\r\n  padding: 10px 0;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n.admin-policy input {\r\n  width: 100%;\r\n  border: 1px solid lightgray;\r\n  padding: 5px 10px;\r\n}\r\n.admin-policy .title {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-top: 40px;\r\n  padding: 20px;\r\n  font-weight: 600;\r\n  font-size: 28px;\r\n}\r\n\r\n.admin-policy .edit-delete {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 7px;\r\n  padding: 0 5px;\r\n}\r\n\r\n/* 테이블 끝 */\r\n\r\n.save-group {\r\n  display: flex;\r\n  width: 100%;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.admin-policy .remark-container {\r\n  padding: 10px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
