// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-manager {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin-manager .search-group {
  margin-bottom: 5px;
}

.admin-manager .rigth-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* 테이블 시작 */
.admin-manager table {
  width: 100%;
}

.admin-manager thead {
  background-color: #5a7087;
  color: white;
}

.admin-manager thead td {
  padding: 10px 0;
  text-align: center;
}

.admin-manager tbody tr {
  border-bottom: 1px solid lightgray;
}
.admin-manager tbody td {
  position: relative;
  padding: 10px 0;
  text-align: center;
  vertical-align: center;
}
.admin-manager tbody td:first-child {
}

/* 테이블 끝 */

.edit-delete {
  display: flex;
  justify-content: center;
}
.edit-delete div:first-child {
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminManager.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA,WAAW;AACX;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;AACA;AACA;;AAEA,UAAU;;AAEV;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".admin-manager {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n.admin-manager .search-group {\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.admin-manager .rigth-btn {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n  width: 100%;\r\n}\r\n\r\n/* 테이블 시작 */\r\n.admin-manager table {\r\n  width: 100%;\r\n}\r\n\r\n.admin-manager thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n\r\n.admin-manager thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.admin-manager tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.admin-manager tbody td {\r\n  position: relative;\r\n  padding: 10px 0;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n.admin-manager tbody td:first-child {\r\n}\r\n\r\n/* 테이블 끝 */\r\n\r\n.edit-delete {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n.edit-delete div:first-child {\r\n  margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
