// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-date-container {
  position: relative;
  display: inline-block;
}

#datePopup {
  display: none;
  position: absolute;
}
.more-left {
  transform: translateX(-30px);
}
`, "",{"version":3,"sources":["webpack://./src/components/DirectRegister.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".custom-date-container {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n#datePopup {\r\n  display: none;\r\n  position: absolute;\r\n}\r\n.more-left {\r\n  transform: translateX(-30px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
