// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}

ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/Paging.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".pagination {\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-top: 15px;\r\n}\r\n\r\nul {\r\n  list-style: none;\r\n  padding: 0;\r\n}\r\n\r\nul.pagination li {\r\n  display: inline-block;\r\n  width: 30px;\r\n  height: 30px;\r\n  border: 1px solid #e2e2e2;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 1rem;\r\n}\r\n\r\nul.pagination li:first-child {\r\n  border-radius: 5px 0 0 5px;\r\n}\r\n\r\nul.pagination li:last-child {\r\n  border-radius: 0 5px 5px 0;\r\n}\r\n\r\nul.pagination li a {\r\n  text-decoration: none;\r\n  color: #337ab7;\r\n  font-size: 1rem;\r\n}\r\n\r\nul.pagination li.active a {\r\n  color: white;\r\n}\r\n\r\nul.pagination li.active {\r\n  background-color: #337ab7;\r\n}\r\n\r\nul.pagination li a:hover,\r\nul.pagination li a.active {\r\n  color: blue;\r\n}\r\n\r\n.page-selection {\r\n  width: 48px;\r\n  height: 30px;\r\n  color: #337ab7;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
