// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-status {
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.search-status span {
  font-weight: 600;
  margin-right: 10px;
}

.search-status select {
  width: 150px;
  border: 1px solid lightgray;
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchStatus.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".search-status {\r\n  display: flex;\r\n  align-items: center;\r\n  padding-right: 24px;\r\n}\r\n\r\n.search-status span {\r\n  font-weight: 600;\r\n  margin-right: 10px;\r\n}\r\n\r\n.search-status select {\r\n  width: 150px;\r\n  border: 1px solid lightgray;\r\n  background-color: white;\r\n  padding: 4px 10px;\r\n  border-radius: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
