// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-today {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
/* 테이블 시작 */
.admin-today table {
  width: 100%;
}

.admin-today thead {
  background-color: #5a7087;
  color: white;
}

.admin-today thead td {
  padding: 10px 0;
  text-align: center;
}

.admin-today tbody tr {
  border-bottom: 1px solid lightgray;
}
.admin-today tbody td {
  position: relative;
  padding: 10px 0;
  text-align: center;
  vertical-align: center;
}
.admin-today tbody td:first-child {
}

/* 테이블 끝 */
.search-group {
  width: 100%;
  display: flex;
  margin: 20px 0;
  padding: 0 10px;
  flex-wrap: wrap;
  gap: 8px;
}

.table-hover {
  cursor: pointer;
}
.table-hover:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminToday.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA,WAAW;AACX;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;AACA;AACA;;AAEA,UAAU;AACV;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,eAAe;EACf,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;AACA;EACE,qCAAqC;AACvC","sourcesContent":[".admin-today {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n/* 테이블 시작 */\r\n.admin-today table {\r\n  width: 100%;\r\n}\r\n\r\n.admin-today thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n\r\n.admin-today thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.admin-today tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.admin-today tbody td {\r\n  position: relative;\r\n  padding: 10px 0;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n.admin-today tbody td:first-child {\r\n}\r\n\r\n/* 테이블 끝 */\r\n.search-group {\r\n  width: 100%;\r\n  display: flex;\r\n  margin: 20px 0;\r\n  padding: 0 10px;\r\n  flex-wrap: wrap;\r\n  gap: 8px;\r\n}\r\n\r\n.table-hover {\r\n  cursor: pointer;\r\n}\r\n.table-hover:hover {\r\n  background-color: rgba(0, 0, 0, 0.02);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
