// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* 테이블 시작 */
.admin-history table {
  width: 100%;
}

.admin-history thead {
  background-color: #5a7087;
  color: white;
}

.admin-history thead td {
  padding: 10px 0;
  text-align: center;
}

.admin-history tbody tr {
  border-bottom: 1px solid lightgray;
}
.admin-history tbody td {
  position: relative;
  padding: 10px 0;
  text-align: center;
  vertical-align: center;
}
.admin-history tbody td:first-child {
}

/* 테이블 끝 */

.excel-group {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-download {
  border: 1px solid lightgray;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: green;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminHistory.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;AACxB;AACA;AACA;;AAEA,UAAU;;AAEV;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".admin-history {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n/* 테이블 시작 */\r\n.admin-history table {\r\n  width: 100%;\r\n}\r\n\r\n.admin-history thead {\r\n  background-color: #5a7087;\r\n  color: white;\r\n}\r\n\r\n.admin-history thead td {\r\n  padding: 10px 0;\r\n  text-align: center;\r\n}\r\n\r\n.admin-history tbody tr {\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n.admin-history tbody td {\r\n  position: relative;\r\n  padding: 10px 0;\r\n  text-align: center;\r\n  vertical-align: center;\r\n}\r\n.admin-history tbody td:first-child {\r\n}\r\n\r\n/* 테이블 끝 */\r\n\r\n.excel-group {\r\n  width: 100%;\r\n  align-items: center;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.btn-download {\r\n  border: 1px solid lightgray;\r\n  padding: 5px 10px;\r\n  border-radius: 5px;\r\n  background-color: green;\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
