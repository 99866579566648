// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-manager-detail {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-manager-detail section {
  width: 90%;
  padding: 10px 0;
}

.reg-title {
  display: flex;
}

.input-group {
  display: flex;
  padding: 10px 10px;
}
.input-group div {
  width: 180px;
}
.input-group input,
.input-group select {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

select:focus {
  outline: none;
}

.btn-container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminManagerDetail.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;;EAEE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".admin-manager-detail {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.admin-manager-detail section {\r\n  width: 90%;\r\n  padding: 10px 0;\r\n}\r\n\r\n.reg-title {\r\n  display: flex;\r\n}\r\n\r\n.input-group {\r\n  display: flex;\r\n  padding: 10px 10px;\r\n}\r\n.input-group div {\r\n  width: 180px;\r\n}\r\n.input-group input,\r\n.input-group select {\r\n  width: 100%;\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n\r\nselect:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-container {\r\n  width: 100%;\r\n  padding: 20px 0;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
