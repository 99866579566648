// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.group-drag {
  display: flex;
  gap: 20px;
}
.drag-parent-lists {
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}
.drag-parent-lists {
  width: 400px;
}
.setting-draggable-list {
  width: 400px;

  cursor: pointer;
  border: 1px solid lightgray;
}
.draggable-title {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  background-color: lightgray;
}
.draggable-group {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}
.edit-delete-btn {
  display: flex;
  gap: 4px;
}
.draggable-contents {
  height: 400px;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/MenuVisitPurpose.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;;EAEZ,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,QAAQ;AACV;AACA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".draggable-menu {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n}\r\n\r\n.group-drag {\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n.drag-parent-lists {\r\n  border: 1px solid lightgray;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n.drag-parent-lists {\r\n  width: 400px;\r\n}\r\n.setting-draggable-list {\r\n  width: 400px;\r\n\r\n  cursor: pointer;\r\n  border: 1px solid lightgray;\r\n}\r\n.draggable-title {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 15px 10px;\r\n  background-color: lightgray;\r\n}\r\n.draggable-group {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 15px 10px;\r\n}\r\n.edit-delete-btn {\r\n  display: flex;\r\n  gap: 4px;\r\n}\r\n.draggable-contents {\r\n  height: 400px;\r\n  overflow-y: scroll;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
