// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/react-datepicker/dist/react-datepicker.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-register {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin-register section {
  width: 1024px;
  padding: 20px 0;
}

.reg-title {
  display: flex;
}

.input-group {
  display: flex;
  padding: 10px 10px;
}
.input-group div {
  width: 180px;
}
.input-group input,
.input-group select {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

select:focus {
  outline: none;
}

.btn-container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/AdminRegister.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;;EAEE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["@import \"react-datepicker/dist/react-datepicker\";\r\n\r\n.admin-register {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n.admin-register section {\r\n  width: 1024px;\r\n  padding: 20px 0;\r\n}\r\n\r\n.reg-title {\r\n  display: flex;\r\n}\r\n\r\n.input-group {\r\n  display: flex;\r\n  padding: 10px 10px;\r\n}\r\n.input-group div {\r\n  width: 180px;\r\n}\r\n.input-group input,\r\n.input-group select {\r\n  width: 100%;\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n\r\nselect:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-container {\r\n  width: 100%;\r\n  padding: 20px 0;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
