// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher-profile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teacher-profile section {
  width: 1024px;
  padding: 20px 0;
}
.reg-title {
  display: flex;
}

.input-group {
  position: relative;
  display: flex;
  padding: 10px 10px;
}
.input-group .abs-btn:nth-child(2) {
  position: absolute;
  right: 0;
  transform: translateY(-100px);
}
.input-group div:first-child {
  width: 180px;
}
.input-group input,
.input-group select {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

select:focus {
  outline: none;
}

.btn-container {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/teacher/TeacherProfile.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd;AACA;;EAEE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".teacher-profile {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.teacher-profile section {\r\n  width: 1024px;\r\n  padding: 20px 0;\r\n}\r\n.reg-title {\r\n  display: flex;\r\n}\r\n\r\n.input-group {\r\n  position: relative;\r\n  display: flex;\r\n  padding: 10px 10px;\r\n}\r\n.input-group .abs-btn:nth-child(2) {\r\n  position: absolute;\r\n  right: 0;\r\n  transform: translateY(-100px);\r\n}\r\n.input-group div:first-child {\r\n  width: 180px;\r\n}\r\n.input-group input,\r\n.input-group select {\r\n  width: 100%;\r\n  border-bottom: 1px solid lightgray;\r\n}\r\n\r\nselect:focus {\r\n  outline: none;\r\n}\r\n\r\n.btn-container {\r\n  width: 100%;\r\n  padding: 20px 0;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
